import React, { useState, useCallback, useEffect } from 'react';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { InvertColors } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useMapComponent } from '../hooks';
import PropTypes from 'prop-types';
import clsx from 'clsx';


export default React.memo ( MapOptions );

function MapOptions ({ className, children, initialOptions = { colors: true } })
{
	const classes = useStyles(),
		{ options, setOptions } = useMapComponent(),
		[ value, setValue ] = useState ( () => getValueFromOptions ( options ) ),
		onChange = useCallback (
			( e, value ) => setOptions ( getOptionsFromValue ( value ) ),
			[]
		);

	useEffect (
		() => {
			setOptions ( initialOptions );
		},
		[]
	);

	useEffect (
		() => {
			setValue ( getValueFromOptions ( options ) );
		},
		[ options ]
	);

	return (
		<ToggleButtonGroup
			className={ clsx ( classes.toggleButtons, className ) }
			size="small"
			{...{ value, onChange }}
		>
			<ToggleButton value="colors">
				<InvertColors />
			</ToggleButton>
			{ children }
		</ToggleButtonGroup>
	);
}

MapOptions.propTypes = {
	initialOptions: PropTypes.object
};


const useStyles = makeStyles ( theme => ({
	toggleButtons: {
		position: 'absolute',
		top: 20,
		left: 20,
		boxShadow: theme.shadows[ 5 ],
		backgroundColor: theme.palette.common.white,
		'& button': {
			width: 40,
			height: 40,
			marginLeft: [ 0, '!important' ],
			borderColor: '#E3E3E3',
			color: '#5A5A5A',
			overflow: 'hidden',
			backgroundColor: theme.palette.common.white,
			'&.MuiToggleButtonGroup-groupedHorizontal:not(:first-child)': {
				borderLeft: 'none'
			},
			'&:hover': {
				backgroundColor: theme.palette.common.white
			},
			'&.Mui-selected': {
				color: theme.palette.primary.main,
				backgroundColor: theme.palette.common.white,
				'&:hover': {
					backgroundColor: theme.palette.common.white
				}
			}
		}
	}
}) );

function getValueFromOptions ( options )
{
	return Object.keys ( options )
		.reduce (
			( res, name ) => options[ name ] ? [ ...res, name ] : res,
			[]
		);
}

function getOptionsFromValue ( value )
{
	return value.reduce (
		( res, name ) => ({ ...res, [ name ]: true }),
		{}
	);
}
