import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';


export default function HeaderLogo ({ className, children, onClick, ...rest })
{
	const classes = useStyles();

	return (
		<Typography
			className={ clsx ( classes.logo, !!onClick && classes.pointer, className ) }
			{...{ onClick }}
			{ ...rest }
		>
			{ children }
		</Typography>
	);
}

HeaderLogo.propTypes = {
	children: PropTypes.string.isRequired
};


const useStyles = makeStyles ( theme => ({
	logo: {
		color: theme.palette.common.white,
		fontSize: 26,
		marginRight: 20
	},
	pointer: {
		cursor: 'pointer'
	}
}) );
