import { useContext, useEffect } from 'react';
import { MainDrawerContext, MapComponentContext } from './context';


export function useMainDrawer ()
{
	const { opened, toggle } = useContext ( MainDrawerContext );

	return { opened, toggle };
}

export function useMapComponent ()
{
	return useContext ( MapComponentContext );
}

export function useMapOptions ()
{
	const { options } = useMapComponent();

	return options;
}
export function useMapOption ( name )
{
	const options = useMapOptions();

	return !!options[ name ];
}

export function useCloseBalloonOnMapClick ()
{
	const { map } = useMapComponent();

	useEffect (
		() => {
			if ( !map ) return;

			const click = () => map.balloon.close();

			map.events.add ( 'click', click );

			return () => map.events.remove ( 'click', click );
		},
		[ map ]
	);
}
