import React, { useCallback } from 'react';
import { Grid, Button, FormGroup, makeStyles } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import { Formik, Form, Field, useField } from 'formik';
import MaskedInput from 'react-text-mask';
import { FormikTextField } from './';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const fixPhone = value => value.replace ( /^\+7 \(7|^\+7 \(8/, '+7(' ),
	mask = {
		phone: [ '+', 7, ' ', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/ ],
		code: [ /\d/, /\d/, /\d/, /\d/ ]
	},
	label = {
		phone: 'Введите Ваш номер телефона',
		code: 'Код из смс'
	};


export default React.memo ( AuthComponent );

function AuthComponent ({ confirm, initialValues, children, onSubmit, onBack })
{
	const classes = useStyles(),
		name = confirm ? 'code' : 'phone';

	return (
		<Grid container className={ classes.root } direction="column" justify="center" alignItems="center">
			<Formik {...{ initialValues, onSubmit }}>
				<Form className={ classes.form }>
					<FormGroup row className={ classes.formGroup }>
						<Field
							key={ name } // kick off autoFocus.
							className={ clsx ( classes.textField, confirm && classes.code ) }
							component={ FormikTextField }
							InputProps={{ inputComponent: TextMaskCustom }}
							inputProps={{ mask: mask[ name ] }}
							label={ label[ name ] }
							autoFocus={ confirm }
							variant="outlined"
							fullWidth
							{...{ name }}
						/>
						<Button disableElevation className={ classes.button } type="submit" variant="contained" color="inherit">
							{ confirm ? 'Подтвердить' : 'Войти' }
						</Button>
					</FormGroup>
					{ confirm &&
						<BackButton
							className={ classes.backButton }
							startIcon={ <ChevronLeft /> }
							variant="outlined"
							color="inherit"
							size="large"
							onClick={ onBack }
						/>
					}
				</Form>
			</Formik>
			{ children }
		</Grid>
	);
}

AuthComponent.propTypes = {
	confirm: PropTypes.bool.isRequired,
	initialValues: PropTypes.shape ({
		phone: PropTypes.string.isRequired,
		code: PropTypes.string.isRequired
	}).isRequired,
	children: PropTypes.any,
	onSubmit: PropTypes.func.isRequired,
	onBack: PropTypes.func.isRequired
};


const useStyles = makeStyles ( ({ palette: { common } }) => ({
	root: {
		position: 'relative',
		height: '100%',
		background: 'linear-gradient(126.25deg, #F8B2FF 0%, #22B0E0 49.12%, #2C5B73 95.07%), #C4C4C4',
		color: '#FFF'
	},
	formGroup: {
		width: 350,
		flexWrap: 'nowrap'
	},
	button: {
		minWidth: 'initial',
		marginLeft: 8,
		backgroundColor: common.baseGreen
	},
	code: {
		'& input': {
			letterSpacing: 5
		}
	},
	textField: {
		'& input': {
			color: common.white,
			'&:-webkit-autofill': {
				backgroundColor: 'rgba(255, 255, 255, 0.01)'
			}
		},
		'& label': {
			color: common.white
		},
		'& label.Mui-focused': {
			color: common.white
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: common.white
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: common.white
			},
			'&:hover fieldset': {
				borderColor: common.white
			},
			'&.Mui-focused fieldset': {
				borderColor: common.white
			}
		}
	},
	backButton: {
		position: 'absolute',
		top: 10,
		left: 10,
		width: 56,
		minWidth: 56,
		height: 56,
		padding: 0,
		'& .MuiButton-startIcon': {
			margin: 0
		}
	}
}) );

function TextMaskCustom ({ inputRef, name, onChange, ...other })
{
	const ref = useCallback (
			ref => {
				inputRef ( ref ? ref.inputElement : null );
			},
			[ inputRef ]
		),
		fixedOnChange = useCallback (
			e => onChange ({
				target: {
					value: fixPhone ( e.target.value ),
					name
				}
			}),
			[ name, onChange ]
		);

	return (
		<MaskedInput
			{...{ ref, name }}
			placeholderChar="_"
			guide={ false }
			{ ...other }
			onChange={ name === 'phone' ? fixedOnChange : onChange }
		/>
	);
}

function BackButton ({ onClick: onClickProp, ...rest })
{
	const [ ,, { setValue } ] = useField ( 'code' ),
		onClick = () => {
			if ( onClickProp ) onClickProp();

			setValue ( '' );
		};

	return (
		<Button {...{ onClick }} { ...rest }/>
	);
}
