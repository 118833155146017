import React from 'react';
import { Toolbar, makeStyles } from '@material-ui/core';
import { useMainDrawer } from '../hooks';
import clsx from 'clsx';


export default React.memo ( MainContent );

function MainContent ({ children })
{
	const classes = useStyles(),
		{ opened } = useMainDrawer();

	return (
		<div className={ clsx ( classes.content, opened && classes.contentShift ) }>
			<Toolbar />
			<div className={ classes.contentWrapper }>
				{ children }
			</div>
		</div>
	);
}


const useStyles = makeStyles ( theme => ({
	content: {
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
		flexShrink: 1,
		transition: theme.transitions.create ( 'margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		} ),
		marginRight: -theme.drawerWidth
	},
	contentShift: {
		transition: theme.transitions.create ( 'margin', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen
		} ),
		marginRight: 0
	},
	contentWrapper: {
		overflow: 'hidden',
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		flex: 1
	}
}) );
