import React, { useCallback } from 'react';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { makeStyles, fade } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';


export default React.memo ( RangeSwitch );

function RangeSwitch ({ className, isRange = false, switchToDate, switchToRange })
{
	const classes = useStyles(),
		onChange = useCallback (
			( e, value ) => {
				if ( value === null ) return;

				if ( value ) switchToRange();
				else switchToDate();
			},
			[ switchToRange, switchToDate ]
		);

	return (
		<ToggleButtonGroup
			className={ clsx ( classes.root, className ) }
			exclusive
			value={ isRange }
			{...{ onChange }}
		>
			<ToggleButton className={ classes.button } value={ false }>
				Дата
			</ToggleButton>
			<ToggleButton className={ classes.button } value={ true }>
				Период
			</ToggleButton>
		</ToggleButtonGroup>
	);
}

RangeSwitch.propTypes = {
	className: PropTypes.string,
	isRange: PropTypes.bool.isRequired,
	switchToDate: PropTypes.func.isRequired,
	switchToRange: PropTypes.func.isRequired
};


const useStyles = makeStyles ( theme => ({
	root: {
		overflow: 'hidden',
		marginRight: theme.spacing ( 2.5 ),
	},
	button: {
		marginLeft: [ 0, '!important' ],
		overflow: 'hidden',
		minHeight: 30,
		minWidth: 74,
		padding: theme.spacing ( 0, 1 ),
		border: 'none',
		color: theme.palette.common.white,
		backgroundColor: fade (
			theme.palette.common.white,
			0.15
		),
		'&:hover': {
			backgroundColor: fade (
				theme.palette.common.white,
				0.2
			)
		},
		transition: 'background-color ease 300ms',
		'&.Mui-selected': {
			color: theme.palette.primary.main,
			backgroundColor: theme.palette.common.white,
			'&:hover': {
				backgroundColor: theme.palette.common.white
			}
		}
	}
}) );
