import React from 'react';
import IcoMoon from 'react-icomoon';
import defaultIconSet from './selection.json';


export default React.memo ( Icon );

function Icon ({ iconSet = defaultIconSet, ...rest })
{
	return <IcoMoon {...{ iconSet }} { ...rest } />;
}
