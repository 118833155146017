import { createMuiTheme } from '@material-ui/core/styles';


export const themeOptions = {
	drawerWidth: 320,
	headerIconsFontSize: 26.5,
	palette: {
		common: {
			baseGreen: '#56B98A',
			blue: '#0066FF',
			yellow: '#FFB054',
			red: '#E51A1A',
			white: '#FFF',
			darkGrey: '#959595'
		},
		primary: {
			main: '#22B0E0'
		},
		secondary: {
			main: '#FFF'
		},
		text: {
			primary: '#000'
		}
	},
	typography: {
		button: {
			textTransform: 'none',
			fontSize: '1.1em'
		}
	},
	overrides: {
		MuiButton: {
			containedPrimary: {
				color: '#FFF'
			}
		},
		MuiPickersToolbarText: {
			toolbarTxt: {
				color: '#FFF'
			},
			toolbarBtnSelected: {
				color: '#FFF'
			}
		},
		MuiPickersDay: {
			daySelected: {
				color: '#FFF'
			}
		}
	}
};

export default createMuiTheme ( themeOptions );
