import React from 'react';
import { withStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';


export default withStyles ({
	version: {
		position: 'absolute',
		bottom: '5vh'
	}
})( AuthVersion );

function AuthVersion ({ classes, className, children, ...rest })
{
	return (
		<Typography
			className={ clsx ( classes.version, className ) }
			variant="body2"
			{ ...rest }
		>
			ver { children }
		</Typography>
	);
}
