import React, { useRef, useState, useCallback, useMemo } from 'react';
import { MapComponentContext } from '../context';
import PropTypes from 'prop-types';



export default React.memo ( MapComponentProvider );

function MapComponentProvider ({ children })
{
	const mapRef = useRef(),
		ymapsRef = useRef(),
		[ map, _setMap ] = useState ( null ),
		[ ymaps, _setYmaps ] = useState ( null ),
		[ options, setOptions ] = useState ({ colors: true }),
		[ templates, setTemplates ] = useState ( null ),
		setMap = useCallback (
			map => {
				mapRef.current = map;

				_setMap ( map );
			},
			[]
		),
		setYmaps = useCallback (
			ymaps => {
				ymapsRef.current = ymaps;

				_setYmaps ( ymaps );
			},
			[]
		),
		value = useMemo (
			() => ({ mapRef, ymapsRef, map, ymaps, options, templates, setMap, setYmaps, setOptions, setTemplates }),
			[ map, ymaps, options, templates, setMap, setYmaps ]
		);

	return (
		<MapComponentContext.Provider {...{ value }}>
			{ children }
		</MapComponentContext.Provider>
	);
}

MapComponentProvider.propTypes = {
	children: PropTypes.any.isRequired
};
