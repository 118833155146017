import React, { useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core';
import { MainDrawerContext } from '../context';
import clsx from 'clsx';


export default React.memo ( MainComponent );

function MainComponent ({ className, children, ...rest })
{
	const classes = useStyles(),
		[ opened, setOpened ] = useState ( true ),
		toggle = useCallback (
			() => setOpened ( opened => !opened ),
			[]
		);

	return (
		<MainDrawerContext.Provider value={{ opened, toggle }}>
			<div className={ clsx ( classes.root, className ) } { ...rest }>
				{ children }
			</div>
		</MainDrawerContext.Provider>
	);
}


const useStyles = makeStyles ( theme => ({
	root: {
		display: 'flex',
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0
	}
}) );
