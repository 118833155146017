import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';


export default React.memo ( MapWrapper );

function MapWrapper ({ className, children, ...rest })
{
	const classes = useStyles();

	return (
		<div className={ clsx ( classes.root, className ) } { ...rest }>
			{ children }
		</div>
	);
}


const useStyles = makeStyles ( theme => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0
	}
}) );
