import React, { useState, useCallback } from 'react';
import { IconButton, Popover, List, ListItem, ListItemText, Divider, useTheme, makeStyles } from '@material-ui/core';
import { AccountCircleOutlined } from '@material-ui/icons';
import { useMainDrawer } from '../hooks';
import PropTypes from 'prop-types';

const anchorOrigin = {
		vertical: 'bottom',
		horizontal: 'right'
	},
	transformOrigin = {
		vertical: 'top',
		horizontal: 'right'
	};


export default React.memo ( HeaderProfile );

function HeaderProfile ({ children, Icon = AccountCircleOutlined, name, iconProps, logout, ...rest })
{
	const theme = useTheme(),
		classes = useStyles(),
		{ opened } = useMainDrawer(),
		[ anchorEl, setAnchorEl ] = useState ( null ),
		onClick = useCallback (
			e => setAnchorEl ( e.currentTarget ),
			[]
		),
		onClose = useCallback (
			() => setAnchorEl ( null ),
			[]
		);

	return (
		<React.Fragment>
			<IconButton
				color="inherit"
				edge={ opened ? 'end' : undefined }
				{ ...rest }
				{...{ onClick }}
			>
				<Icon style={{ fontSize: theme.headerIconsFontSize }} { ...iconProps } />
			</IconButton>
			<Popover
				open={ !!anchorEl }
				{...{ anchorEl, anchorOrigin, transformOrigin, onClose }}
			>
				{ children ||
					<List disablePadding className={ classes.list }>
						<ListItem>
							<ListItemText
								className={ classes.text }
								primary={
									name
										.split ( ' ' )
										.map (
											( part, i ) => (
												<span key={ `${ part }-${ i }` }>
													{ part }
													<br />
												</span>
											)
										)
								}
							/>
						</ListItem>
						<Divider />
						<ListItem button onClick={ logout }>
							<ListItemText className={ classes.logout } primary="Выйти" />
						</ListItem>
						{/*<Button fullWidth className={ classes.button } color="primary">
							Выйти
						</Button>*/}
					</List>
				}
			</Popover>
		</React.Fragment>
	);
}

HeaderProfile.propTypes = {
	Icon: PropTypes.elementType,
	children: PropTypes.element,
	name: PropTypes.string,
	iconProps: PropTypes.object,
	logout: PropTypes.func
};


const useStyles = makeStyles ( theme => ({
	list: {
		minWidth: 150
	},
	text: {
		'& span': {
			textAlign: 'center'
		}
	},
	logout: {
		'& span': {
			textAlign: 'center',
			color: theme.palette.primary.main
		}
	},
	/* button: {
		padding: theme.spacing ( 1 )
	} */
}) );
