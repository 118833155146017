import React from 'react';
import { Toolbar, Drawer, IconButton, Divider, useTheme, makeStyles } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { useMainDrawer } from '../hooks';
import clsx from 'clsx';


export default React.memo ( MainDrawer );

function MainDrawer ({ className, children, toolbarProps, iconButtonProps, iconProps, ...rest })
{
	const theme = useTheme(),
		classes = useStyles(),
		{ opened, toggle } = useMainDrawer();

	return (
		<Drawer
			className={ clsx ( classes.drawer, className ) }
			variant="persistent"
			anchor="right"
			open={ opened }
			classes={{ paper: classes.drawerPaper }}
			{ ...rest }
		>
			<Toolbar { ...toolbarProps }>
				<IconButton edge="start" onClick={ toggle } { ...iconButtonProps }>
					<ChevronRight style={{ fontSize: theme.headerIconsFontSize }} { ...iconProps } />
				</IconButton>
			</Toolbar>
			<Divider />
			{ children }
		</Drawer>
	);
}


const useStyles = makeStyles ( theme => ({
	drawer: {
		width: theme.drawerWidth,
		flexShrink: 0
	},
	drawerPaper: {
		position: 'relative',
		width: theme.drawerWidth,
		overflow: 'hidden'
	}
}) );
